/* src/App.css */

.App {
  font-family: Arial, sans-serif;
  text-align: center;
  padding: 20px;
  background-color: #f0f4f8; /* Site background color */
  min-height: 100vh; /* Ensure background covers entire viewport */
  display: flex;
  flex-direction: column;
}

header {
  margin-bottom: 20px;
}

header h1 {
  color: #333;
  margin-bottom: 10px;
}

header h2 {
  font-size: 18px;
  color: #555;
  margin-bottom: 10px;
}

header p {
  font-size: 16px;
  color: #666;
  max-width: 800px;
  margin: 0 auto;
}

.top-card {
  margin-bottom: 20px;
}

.top-card .card {
  display: inline-block;
  width: 300px;
  margin: 0 auto;
}

.content {
  flex: 1; /* Allow content to grow and push footer down */
  margin-top: 20px;
}

.categories {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  overflow-x: auto;
  margin-bottom: 30px; /* Added spacing below categories */
}

.category {
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 18%;
  min-height: 200px;
  padding: 10px;
  box-sizing: border-box;
  margin-right: 10px;
  position: relative;
  background-size: cover;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* Added box-shadow */
}

.category:last-child {
  margin-right: 0;
}

.category h2 {
  font-size: 18px;
  margin-bottom: 10px;
  color: #444;
}

.card {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  cursor: grab;
  text-align: left;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.card h3 {
  margin: 0 0 5px;
  font-size: 16px;
  color: #333;
}

.card p {
  margin: 0;
  font-size: 14px;
  color: #555;
}

.card:active {
  cursor: grabbing;
}

.generated-url {
  margin-top: 20px;
  text-align: center;
}

.generated-url p {
  margin-bottom: 10px;
  font-size: 16px;
  color: #333;
}

.generated-url input {
  width: 80%;
  max-width: 600px;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

footer {
  text-align: center;
  padding: 20px 0;
  background-color: #f0f4f8; /* Match site's background color */
}

footer p {
  margin: 5px 0 0;
  font-size: 14px;
  color: #777;
}

.github-logo {
  width: 32px;
  height: 32px;
  margin-bottom: 5px;
}

@media (max-width: 768px) {
  .category {
    width: 100%;
    margin-bottom: 20px;
  }

  .categories {
    flex-direction: column;
    overflow-x: visible;
  }
}

/* Optional: Style scrollbar for WebKit browsers */
.categories::-webkit-scrollbar {
  height: 8px;
}

.categories::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}

/* Buttons Container */
.buttons {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-bottom: 20px;
}

/* Shared Button Styles */
button {
  padding: 12px 24px;
  font-size: 16px;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

/* Generate URL Button */
.generate-url {
  background-color: #8ebdbb; /* Pastel teal */
}

.generate-url:hover {
  background-color: #92c9c7;
}

.generate-url:active {
  background-color: #82bdbb;
}

.generate-url:disabled {
  background-color: #c8e9e8;
  cursor: not-allowed;
}

/* Reset Button */
.reset-button {
  background-color: #e0a6a6; /* Pastel red */
}

.reset-button:hover {
  background-color: #f5a8a8;
}

.reset-button:active {
  background-color: #f39999;
}

/* Next and Previous Buttons */
.navigation-buttons {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 10px;
}

.prev-button,
.next-button {
  background-color: #b2a1e6; /* Pastel purple */
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.prev-button:hover,
.next-button:hover {
  background-color: #b4a8f5;
}

.prev-button:active,
.next-button:active {
  background-color: #a599f3;
}

.prev-button:disabled,
.next-button:disabled {
  background-color: #e0e0e0;
  cursor: not-allowed;
}

.copy-message {
  margin-top: 10px;
  font-size: 16px;
  color: #4CAF50;
}